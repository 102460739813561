import React from 'react';
import TodoList from './Component/TodoList';

const MyPage = () => {
  return (
    <>
      <TodoList />
    </>
  );
};

export default MyPage;
